import './App.css';
import { useState } from 'react';

const App = () => {
  const [loading, setLoading] = useState(false);
  const [display, setDisplay] = useState(true);

  const loadScriptAndShowLocker = () => {
    setLoading(true); // Rozpocznij ładowanie i pokazanie animacji buforowania
    setDisplay(false);

    const lockerId = "cpa-locker-2b9f5470-8e5d-11eb-8e19-a38b8c83575e";
    const scriptId = "cpljs-2b9f5470-8e5d-11eb-8e19-a38b8c83575e";

    // Sprawdź, czy skrypt nie został już wcześniej załadowany
    if (!document.getElementById(scriptId)) {
      const script = document.createElement('script');
      script.src = "//captchalocker.pl/iframeLoader/2b9f5470-8e5d-11eb-8e19-a38b8c83575e?";
      script.id = scriptId;
      script.type = 'text/javascript';
      document.body.appendChild(script);

      script.onload = () => {
        // Opóźnienie ukrycia animacji buforowania o 2 sekundy po załadowaniu skryptu
          setLoading(false);
          console.log('Skrypt i locker zostały załadowane.');
      };

      script.onerror = () => {
        // Opóźnienie ukrycia animacji buforowania o 2 sekundy w przypadku błędu
          setLoading(false);
          console.error('Wystąpił błąd podczas ładowania skryptu.');
      };
    } else {
      // Jeśli skrypt jest już załadowany, także użyj opóźnienia przed ukryciem loadera
        setLoading(false);
        console.log('Skrypt jest już załadowany.');
    }
  };

  return (
    <>
      <div className='container'>
        <div className='title'><img src="/img/logo.png"/></div>
        <div className='subtitle'>Gratulacje! to już ostatnie vouchery zalando 500zł. Odbierz go klikając poniższy przycisk.</div>
      </div>
      <div className='c-btn'>
        <button style={{display: display? 'block' : 'none'}} onClick={loadScriptAndShowLocker} className='btn'>Odbierz i wyświetl kod vouchera</button>
        {loading && <div className="loader"></div>}
        <div id="cpa-locker-2b9f5470-8e5d-11eb-8e19-a38b8c83575e"></div>
      </div>
    </>
  );
}

export default App;
